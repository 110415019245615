import get from "lodash/get";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Box, Switch, Tooltip } from "@mui/material";
import { lightTheme, darkTheme } from "@tesseract/theme";
import { useCallback } from "react";
import { Props } from "./types";

function DarkModeToggle({ currentAccount, appColors, updateAppColors }: Props) {
  const handleToggle = useCallback(() => {
    updateAppColors(
      appColors.type === "dark"
        ? (() => {
            if (
              [
                "primaryColor",
                "secondaryColor",
                "tertiaryColor",
                "outboundMessageColor",
              ].every((themeSetting) => {
                return Boolean(
                  get(currentAccount, ["settings", themeSetting, "value"]),
                );
              })
            ) {
              const {
                settings: {
                  primaryColor: { value: primaryColor },
                  secondaryColor: { value: secondaryColor },
                  tertiaryColor: { value: tertiaryColor },
                  outboundMessageColor: { value: outboundMessageColor },
                },
              } = currentAccount;
              return {
                primaryColor,
                secondaryColor,
                tertiaryColor,
                outboundMessageColor,
                type: "light",
              };
            }
            return lightTheme;
          })()
        : darkTheme,
    );
  }, [appColors.type, currentAccount, updateAppColors]);

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Toggle Dark Mode ⌘U">
        {appColors.type === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </Tooltip>
      <Switch
        color="primary"
        checked={appColors.type === "dark"}
        onChange={handleToggle}
        name="darkmode"
        inputProps={{ "aria-label": "Toggle Dark Mode" }}
      />
    </Box>
  );
}

export default DarkModeToggle;
